import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/edelweiss.svg'
import soundcloud from '../img/social/soundcloud.svg'
import youtube from '../img/social/youtube.svg'



const Footer = () => (
  <footer className="site-footer">
    <div className="content has-text-centered">
      <div className="logo">
        <Link to="/">
          <img src={logo} style={{ width: '88px' }} alt="Edelweiss" />
        </Link>
      </div>
      <nav className="social-nav">
        <a title="soundcloud" href="https://soundcloud.com/user-157151914">
            <img
              src={soundcloud}
              alt="Soundcloud"
              style={{ width: '44px', height: '44px' }}
            />
          </a>
        </nav>
      <p>
        <strong>© {new Date().getFullYear()} MGV Edelweiß Kell e.V.</strong> <small>Internet by <a href="https://kdsweb.de" target="_blank">KDSweb.de</a></small>
      </p>
      <div className="footer-links">
        <Link to="/">Home</Link>
        <Link to="/datenschutz/">Datenschutz</Link>
        <Link to="/impressum/">Impressum</Link>
      </div>
    </div>
  </footer>
)

export default Footer
